<template>
  <v-app class="white">
    <div>
      <Content v-bind:theme="whiteTheme">
        <v-layout column justify-center>
          <div class="ml-auto mr-auto text-center cdp-title mb-4">
            <h1 class="">Newsletters</h1>
            <v-layout justify-center>
              <v-flex xs10>
                <v-divider />
              </v-flex>
            </v-layout>
            <p>
              Please email us @commondayproject.gmail with your email address if
              you wish to be subscribed to our newsletters.
            </p>
          </div>

          <v-flex class="text-center">
            <v-btn outline fab color="grey" v-scroll-to="'#scrollTo'">
              <v-icon>keyboard_arrow_down</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </Content>
    </div>
    <v-tabs
      id="scrollTo"
      color="black"
      dark
      slider-color="yellows"
      centered
      grow
    >
      <v-tab v-for="(tab, i) in tabs" :key="i">
        {{ tab.date }}
      </v-tab>
      <v-tab-item v-for="(tab, n) in tabs" :key="n">
        <v-container>
          <v-carousel height="auto" :cycle="cycle" :light="tab.theme">
            <v-carousel-item
              v-for="(newsletter, i) in tab.newsletter"
              :key="i"
              :src="newsletter.src"
            ></v-carousel-item>
          </v-carousel>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </v-app>
</template>

<script>
import Content from "@/components/Content";

export default {
  components: {
    Content,
  },
  data() {
    return {
      whiteTheme: {
        white: true,
        black: false,
        fullHeight: true,
      },

      blackTheme: {
        black: true,
        white: false,
        fullHeight: true,
      },
      cycle: false,
      light: true,
      dates: ["Sept 2019", "May 2020", "July 2021"],
      tabs: [
        {
          date: "Sept 2019",
          theme: false,
          newsletter: [
            {
              src: require("../assets/news_sept_1.jpg"),
            },
            {
              src: require("../assets/news_sept_2.jpg"),
            },
          ],
        },
        {
          date: "May 2020",
          theme: false,
          newsletter: [
            {
              src: require("../assets/news_may_20_1.jpeg"),
            },
            {
              src: require("../assets/news_may_20_3.jpeg"),
            },
            {
              src: require("../assets/news_may_20_4.jpeg"),
            },
            {
              src: require("../assets/news_may_20_5.jpeg"),
            },
          ],
        },
        {
          date: "July 2021",
          theme: true,
          newsletter: [
            {
              src: require("../assets/news_july_21_1.jpeg"),
            },
            {
              src: require("../assets/news_july_21_2.jpeg"),
            },
            {
              src: require("../assets/news_july_21_3.jpeg"),
            },
            {
              src: require("../assets/news_july_21_4.jpeg"),
            },
            {
              src: require("../assets/news_july_21_5.jpeg"),
            },
          ],
        },
        {
          date: "Jan 2022",
          theme: true,
          newsletter: [
            {
              src: require("../assets/news_jan_22_1.jpeg"),
            },
            {
              src: require("../assets/news_jan_22_2.jpeg"),
            },
            {
              src: require("../assets/news_jan_22_3.jpeg"),
            },
            {
              src: require("../assets/news_jan_22_4.jpeg"),
            },
            {
              src: require("../assets/news_jan_22_5.jpeg"),
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped media="screen"></style>
