  <template>
  <v-app class="white">
    <div>
      <Content v-bind:theme="whiteTheme">
        <v-layout column>
          <div class="ml-auto mr-auto text-center cdp-title">
            <h1 class>Project Sixth</h1>
            <v-layout justify-center>
              <v-flex xs4>
                <v-divider />
              </v-flex>
            </v-layout>
            <p>
              Project Sixth is an eco-friendly initiative to clean our polluted communities and to raise awareness to our neighbors the importance of safeguarding our ecosystem. Simply,
              <i>we go out to certain parts of our community to pick up trash</i>.
            </p>
          </div>
          <v-flex class="text-center">
            <v-btn outline fab color="grey" v-scroll-to="'#scrollTo'">
              <v-icon>keyboard_arrow_down</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </Content>
    </div>
    <div id="scrollTo" class="blackBackground screenPadding">
      <Content v-bind:theme="blackTheme">
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <h2 class="mb-4 white--text">Mission</h2>
            <h5 class="mb-2 white--text">
              On the
              <i>Sixth</i> day
            </h5>
            <p class="text-white-50">
              God blessed them, "Prosper! Reproduce! Fill Earth! Take charge! Be responsible for fish in the sea and birds in the air, for every living thing that moves on the face of Earth."
              <strong>Gen 1:28</strong>
            </p>
          </div>
        </div>
      </Content>
    </div>

    <div class="screenPadding">
      <!-- <v-container fluid> -->
      <v-layout justify-center row wrap>
        <v-flex xs12 md6>
          <img class="image-fit" :src="require('../assets/sixth_3.jpeg')" />
        </v-flex>
        <v-flex xs12 md6>
          <img class="image-fit" :src="require('../assets/sixth_7.jpeg')" />
        </v-flex>
        <v-flex xs12 md4>
          <img class="image-fit" :src="require('../assets/sixth_9.jpeg')" />
        </v-flex>
        <v-flex xs12 md4>
          <img class="image-fit" :src="require('../assets/sixth_4.jpeg')" />
        </v-flex>
        <v-flex xs12 md4>
          <img class="image-fit" :src="require('../assets/sixth_6.jpeg')" />
        </v-flex>
        <v-flex xs12 md6>
          <img class="image-fit" :src="require('../assets/sixth_2.jpeg')" />
        </v-flex>
        <v-flex xs12 md6>
          <img class="image-fit" :src="require('../assets/sixth_1.jpeg')" />
        </v-flex>
        <v-flex xs12 md4>
          <img class="image-fit" :src="require('../assets/sixth_8.jpeg')" />
        </v-flex>
        <v-flex xs12 md4>
          <img class="image-fit" :src="require('../assets/sixth_5.jpeg')" />
        </v-flex>
        <v-flex xs12 md4>
          <img class="image-fit" :src="require('../assets/sixth_12.jpeg')" />
        </v-flex>
        <v-flex xs12 md6>
          <img class="image-fit" :src="require('../assets/sixth_11.jpeg')" />
        </v-flex>
        <v-flex xs12 md6>
          <img class="image-fit" :src="require('../assets/sixth_14.jpeg')" />
        </v-flex>
        <v-flex xs12 md6>
          <img class="image-fit" :src="require('../assets/sixth_13.jpeg')" />
        </v-flex>
        <v-flex xs12 md6>
          <img class="image-fit" :src="require('../assets/sixth_10.jpeg')" />
        </v-flex>

        <!-- <v-flex xs12 md4>
              
        </v-flex>-->
        <!-- <v-flex xs12 md4>
              <v-card>
                <v-card-title>
                  <v-img height="250px" width="200px" contain aspect-ratio="1" :src="require('../assets/sixth_1.jpeg')"></v-img>
                </v-card-title>
              </v-card>
        </v-flex>-->
      </v-layout>
      <!-- </v-container> -->
    </div>
  </v-app>
</template>

  <script>
import Content from "@/components/Content";

export default {
  components: {
    Content
  },
  data() {
    return {
      whiteTheme: {
        white: true,
        black: false,
        fullHeight: true
      },

      blackTheme: {
        black: true,
        white: false,
        fullHeight: true
      }
    };
  }
};
</script>

  <style scoped media="screen">
.image-fit {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>
