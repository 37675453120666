<template>
  <v-app class="white">
    <div>
      <Content v-bind:theme="whiteTheme">
        <v-layout column>
          <div class="ml-auto mr-auto text-center cdp-title">
            <h1 class>Project Fisherman</h1>
            <v-layout justify-center>
              <v-flex xs4>
                <v-divider />
              </v-flex>
            </v-layout>
            <p>
              Project Fisherman is an academic scholarship program designed to
              aid aspiring students in need. By supporting students continue
              their education, we are planting the seeds that will ultimately
              grow to instill hope and compassion wherever they go.
            </p>
          </div>
          <v-flex class="text-center">
            <v-btn outline fab color="grey" v-scroll-to="'#scrollTo'">
              <v-icon>keyboard_arrow_down</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </Content>
    </div>
    <div id="scrollTo" class="blackBackground screenPadding">
      <Content v-bind:theme="blackTheme">
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <h2 class="mb-4 white--text">Mission</h2>
            <p class="text-white-50">
              We give because we first received. For everything we have did not
              originate from us but was also given freely; therefore we gather
              the little that we have for anyone who needs. Believing, the act
              of giving will continue.
            </p>
          </div>
        </div>
      </Content>
    </div>

    <div class="screenPadding">
      <v-container class>
        <v-layout justify-center row wrap>
          <v-flex xs12>
            <h2 class="ma-4">
              The 12th Project Fisherman has started and this is how to apply:
            </h2>
          </v-flex>
          <v-divider />
          <v-flex xs12>
            <v-card class="ma-4">
              <v-card-title>
                <div>
                  <h3>Requirement</h3>
                  <div>
                    Any undergraduate/graduate student affiliated with RKCM
                    (Rutgers Korean Campus Ministry) and/or Calvary Korean
                    United Methodist Church
                  </div>
                </div>
              </v-card-title>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <v-card class="ma-4">
              <v-card-title>
                <div>
                  <h3>Application</h3>
                  <div>
                    Send the essay, statement of need, a description of how you
                    are doing, and
                    <a href="./application.pdf" download>application</a> to
                    <strong>commondayproject@gmail.com</strong>
                  </div>
                  <br />
                  <div>
                    <strong>Deadline: 06/08/2024</strong>
                  </div>
                  <div>
                    <strong>Announcement: 06/22/2024</strong>
                  </div>
                </div>
              </v-card-title>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <v-card class="ma-4">
              <v-card-title>
                <div>
                  <h3 class="mb-4">
                    Essay (Choose one topic from the following)
                  </h3>
                  <div class="mb-4">
                    1. What is your understanding of Christian leadership?
                  </div>
                  <div class="mb-4">
                    2. Talk to us about a turning point in your life and how it
                    has impacted who you are today
                  </div>
                  <div class="mb-4">
                    3. Please define being humble before God.
                  </div>
                  <div>
                    <strong>(minimum of 500 words)</strong>
                  </div>
                </div>
              </v-card-title>
            </v-card>
          </v-flex>
          <v-divider></v-divider>
          <v-flex xs12>
            <v-card class="ma-4">
              <v-card-title>
                <div>
                  <h3>Statement of Need</h3>
                  <div>
                    As part of our effort to assess the financial needs of our
                    applicants, please describe to us why you need this
                    scholarship to the best of your ability.
                    <strong>(less than 300 words)</strong>
                  </div>
                </div>
              </v-card-title>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <v-card class="ma-4">
              <v-card-title>
                <div>
                  <h3>How are you doing?</h3>
                  <div>
                    No specific guidelines here, freely let us know how you're
                    doing right now.
                  </div>
                </div>
              </v-card-title>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <div id="scrollTo" class="blackBackground screenPadding">
      <Content v-bind:theme="blackTheme">
        <v-container>
          <v-layout>
            <h1 class="white--text">Prior Recipients</h1>
          </v-layout>
          <v-divider dark></v-divider>
          <v-layout align-center justify-space-between row fill-height>
            <h4 class="white--text">Jan 2019</h4>
            <p class="white--text">Dawon Seo</p>
            <p class="white--text">Esther Lee</p>
          </v-layout>
          <v-divider dark></v-divider>
          <v-layout align-center justify-space-between row fill-height>
            <h4 class="white--text">Aug 2019</h4>
            <p class="white--text">Chawon Seo</p>
            <p class="white--text">Hyemin Sung</p>
          </v-layout>
          <v-divider dark></v-divider>
          <v-layout align-center justify-space-between row fill-height>
            <h4 class="white--text">Jan 2020</h4>
            <p class="white--text">Yerim Cho</p>
          </v-layout>
          <v-divider dark></v-divider>
          <v-layout align-center justify-space-between row fill-height>
            <h4 class="white--text">Aug 2020</h4>
            <p class="white--text">Chawon Seo</p>
            <p class="white--text">Dawon Seo</p>
          </v-layout>
          <v-divider dark></v-divider>
          <v-layout align-center justify-space-between row fill-height>
            <h4 class="white--text">Jan 2021</h4>
            <p class="white--text"></p>
          </v-layout>
          <v-divider dark></v-divider>
          <v-layout align-center justify-space-between row fill-height>
            <h4 class="white--text">Aug 2021</h4>
            <p class="white--text">Sam Park</p>
            <p class="white--text">Angelina Haw</p>
            <p class="white--text">Seiyon Kim</p>
          </v-layout>
          <v-divider dark></v-divider>
          <v-layout align-center justify-space-between row fill-height>
            <h4 class="white--text">Jan 2022</h4>
            <p class="white--text">Hope Lee</p>
            <p class="white--text">Tae Hun Chung</p>
          </v-layout>
          <v-divider dark></v-divider>
          <v-layout align-center justify-space-between row fill-height>
            <h4 class="white--text">Aug 2022</h4>
            <p class="white--text">James Hong</p>
          </v-layout>
          <v-divider dark></v-divider>
          <v-layout align-center justify-space-between row fill-height>
            <h4 class="white--text">Jan 2023</h4>
            <p class="white--text">Sara Park</p>
            <p class="white--text">Sam Park</p>
          </v-layout>
          <v-divider dark></v-divider>
          <v-layout align-center justify-space-between row fill-height>
            <h4 class="white--text">Aug 2023</h4>
            <p class="white--text">Sara Park</p>
            <p class="white--text">Habeen Jun</p>
            <p class="white--text">Angelina Haw</p>
          </v-layout>
          <v-divider dark></v-divider>
          <v-layout align-center justify-space-between row fill-height>
            <h4 class="white--text">Jan 2024</h4>
            <p class="white--text">Sunghyun Cha</p>
            <p class="white--text">Esther An</p>
          </v-layout>
        </v-container>
      </Content>
    </div>
  </v-app>
</template>

<script>
import Content from "@/components/Content";

export default {
  components: {
    Content,
  },
  data() {
    return {
      whiteTheme: {
        white: true,
        black: false,
        fullHeight: true,
      },

      blackTheme: {
        black: true,
        white: false,
        fullHeight: true,
      },
    };
  },
};
</script>

<style scoped media="screen"></style>
